import React, { useEffect, useState } from "react";
import {
  Tab,
  Tabs,
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Checkbox,
  FormControlLabel,
  AppBar,
  Button,
  Paper,
  Grid,
  Divider,
  styled,
  CircularProgress
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CategoryIcon from "@mui/icons-material/Category";
import { getScopeCategoryData } from "../../services/corporatecanvas";
import axios from "axios";
import { putLocationData } from "../../services/corporatecanvas";
import { Scope3Initial } from "./Scope3Initial";
import { enqueueSnackbar } from "notistack";

const ResetButton = styled(Button)({
  textTransform: "none",
  boxShadow: "none",
  fontSize: 16,
  fontWeight: "bolder",
  width: "15%",
  border: "1px solid",
  lineHeight: 1.5,
  backgroundColor: "#fff",
  borderColor: "#FA3F3F",
  color: "#FA3F3F",
  fontFamily: "Poppins",
  alignItems: "center",
  justifyContent: "space-evenly",
  "&:hover": {
    backgroundColor: "#fff",
    borderColor: "#FA3F3F",
    boxShadow: "0px 3px 8px rgba(0, 0, 0, 0.24)",
  },
});

const SaveButton = styled(Button)({
  textTransform: "none",
  boxShadow: "none",
  fontSize: 16,
  fontWeight: "bolder",
  width: "15%",
  border: "1px solid #149BA1",
  lineHeight: 1.5,
  backgroundColor: "#E2E2E2",
  color: "#030229",
  fontFamily: "Poppins",
  alignItems: "center",
  justifyContent: "space-evenly",
  "&:hover": {
    backgroundColor: "#E2E2E2",
    borderColor: "#149BA1",
    boxShadow: "0px 3px 8px rgba(0, 0, 0, 0.24)",
  },
});

const buttonGroupStyle = {
  width: "92%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-evenly",
  padding: "10px 0",
  position: "fixed",
  bottom: 0,
  backgroundColor: "#f6f8fe",
};

const StyledAccordion = styled(Accordion)({
  border: "1px solid #ccc",
  borderRadius: "8px",
  marginBottom: "10px",
  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
  "&:before": {
    display: "none",
  },
});

const StyledAccordionSummary = styled(AccordionSummary)({
  backgroundColor: "#f5f5f5",
  borderBottom: "1px solid #ccc",
  "& .MuiAccordionSummary-content": {
    margin: "12px 0",
  },
});

const StyledAccordionDetails = styled(AccordionDetails)({
  padding: "16px",
  backgroundColor: "#fff",
});

const CategoryBox = styled(Box)({
  marginBottom: "10px",
  padding: "12px 5px",
  marginRight: "5px",
  cursor: "pointer",
  borderRadius: "4px",
  fontFamily: "poppins",
  pl: 1,
  ml: 1,
  backgroundColor: "#fff",
  border: "1px solid #ccc",
  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
  display: "flex",
  alignItems: "center",
  "&:hover": {
    backgroundColor: "#e0f7fa",
  },
  "&.selected": {
    backgroundColor: "#b2ebf2",
    borderColor: "#00bcd4",
  },
});

const CategoryIconWrapper = styled(Box)({
  marginRight: "8px",
  color: "#00bcd4",
});

export const ScopeConfig = ({ location }) => {
  const [scopeCategories, setScopeCategories] = useState({});
  const [selectedScope, setSelectedScope] = useState("Scope 1");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedItems, setSelectedItems] = useState({});
  const [expandedActivity, setExpandedActivity] = useState(null);
  const [newSchema, setNewSchema] = useState({});
  const [country, setCountry] = useState(null)
  const [confirmSubmission, setConfirmSubmission] = useState(false)
  const [showLoader, setShowLoader] = useState(false)
  const [showScope3Initial, setShowScope3Initial] = useState(true);
  const [categorySelections, setCategorySelections] = useState({});
  const [isNextDisabled, setIsNextDisabled] = useState(true);

  const handleSubmit = async () => {
    setShowLoader(true);
    try {
      const records = await putLocationData({
        locationId: location.id,
        configuredTemplate: selectedItems
      });
      enqueueSnackbar('Location updated successfully', { variant: 'success', autoHideDuration: 3000 });
    } catch (error) {
      console.error("Error sending the data", error);
      enqueueSnackbar('Failed to update location', { variant: 'error', autoHideDuration: 3000 });
    } finally {
      setShowLoader(false);
      setConfirmSubmission(false);
    }
  };

  const handleFormReset = () => {
    setSelectedItems({})
  };
  const findCountry = async (city) => {
    const response = await axios.get('https://nominatim.openstreetmap.org/search', {
      params: {
        q: city,
        format: 'json',
        addressdetails: 1,
        limit: 1
      },
    });

    setCountry(response?.data[0].address.country)
  }
  const handleSelectionChange = (
    scope,
    category,
    activity,
    inputLabel,
    subInputLabel
  ) => {
    setSelectedItems((prevSelected) => {
      const newSelected = JSON.parse(JSON.stringify(prevSelected));

      if (!newSelected[scope]) newSelected[scope] = { categories: {} };
      if (!newSelected[scope].categories[category])
        newSelected[scope].categories[category] = { activity: {} };

      // Modified activity selection/deselection logic
      if (activity && !inputLabel) {
        if (newSelected[scope].categories[category].activity[activity]) {
          // If the activity exists, remove it (deselect)
          delete newSelected[scope].categories[category].activity[activity];
        } else {
          // If the activity doesn't exist, add it (select)
          newSelected[scope].categories[category].activity[activity] = {
            inputs: [],
          };
        }
      } else if (inputLabel) {
        if (!newSelected[scope].categories[category].activity[activity])
          newSelected[scope].categories[category].activity[activity] = {
            inputs: [],
          };

        const activityInputs =
          newSelected[scope].categories[category].activity[activity].inputs;
        const scopeCategoryActivity =
          scopeCategories[scope]?.categories[category]?.activity[activity];

        if (subInputLabel) {
          // Handle subInput selection for inputs with multiple subInputs
          const inputIndex = activityInputs.findIndex(
            (input) => input.label === inputLabel
          );
          if (inputIndex > -1) {
            const subInputIndex = activityInputs[inputIndex].inputs.findIndex(
              (subInput) => subInput.label === subInputLabel
            );
            if (subInputIndex > -1) {
              activityInputs[inputIndex].inputs.splice(subInputIndex, 1);
            } else {
              const subInputData = scopeCategoryActivity?.inputs
                .find((input) => input.label === inputLabel)
                ?.inputs.find((subInput) => subInput.label === subInputLabel);
              activityInputs[inputIndex].inputs.push({
                label: subInputLabel,
                methods: subInputData?.methods || [],
              });
            }
          } else {
            const inputData = scopeCategoryActivity?.inputs
              .find((input) => input.label === inputLabel)

            const subInputData = inputData?.inputs.find((subInput) => subInput.label === subInputLabel);
            activityInputs.push({
              type: inputData?.type,
              label: inputLabel,
              inputs: [
                {
                  label: subInputLabel,
                  methods: subInputData?.methods || [],
                },
              ],
            });
          }
        } else {
          const inputData = scopeCategoryActivity?.inputs.find(
            (input) => input.label === inputLabel
          );
          const inputIndex = activityInputs.findIndex(
            (input) => input.label === inputLabel
          );

          if (inputIndex > -1) {
            // If input is being deselected, remove it
            activityInputs.splice(inputIndex, 1);
          } else {
            // If input is being selected
            if (inputData?.inputs.length === 1) {
              // Auto-select the single subInput
              activityInputs.push({
                type: inputData?.type,
                label: inputLabel,
                inputs: [
                  {
                    label: inputData.inputs[0].label,
                    methods: inputData.inputs[0].methods || [],
                  },
                ],
              });
            } else {
              // For multiple subInputs, just add the input without subInputs
              activityInputs.push({
                type: inputData?.type,
                label: inputLabel,
                inputs: [],
              });
            }
          }
        }
      }

      // Clean up empty objects
      if (Object.keys(newSelected[scope].categories[category].activity).length === 0) {
        delete newSelected[scope].categories[category];
      }
      if (Object.keys(newSelected[scope].categories).length === 0) {
        delete newSelected[scope];
      }

      return newSelected;
    });
  };

  useEffect(() => {
    findCountry(location?.name)

    // Set the first category as selected by default
    if (scopeCategories[selectedScope] && Object.keys(scopeCategories[selectedScope].categories).length > 0) {
      setSelectedCategory(Object.keys(scopeCategories[selectedScope].categories)[0]);
    }

  }, [selectedScope, scopeCategories])

  const handleScope3Next = () => {
    setShowScope3Initial(false);
  };
  console.log(selectedItems)
  const handleCountryAssignment = (scope,
    category,
    activity) => {
    setSelectedItems((prevSelected) => {
      const newSelected = JSON.parse(JSON.stringify(prevSelected));

      if (!newSelected[scope]) newSelected[scope] = { categories: {} };
      if (!newSelected[scope].categories[category])
        newSelected[scope].categories[category] = { activity: {} };

      if (activity) {
        if (newSelected[scope].categories[category].activity[activity]) {
          // If the activity exists, remove it (deselect)
          delete newSelected[scope].categories[category].activity[activity];
        } else {
          const inputData = scopeCategories[scope].categories[category].activity[activity].inputs.find((item) => item.label === country)
          console.log(inputData)
          newSelected[scope].categories[category].activity[activity] = {
            inputs: [
              {
                type: inputData?.type,
                label: country,
                inputs: inputData.inputs || []
              }
            ],
          };
        }
      }

      // Clean up empty objects
      if (Object.keys(newSelected[scope].categories[category].activity).length === 0) {
        delete newSelected[scope].categories[category];
      }
      if (Object.keys(newSelected[scope].categories).length === 0) {
        delete newSelected[scope];
      }

      return newSelected;
    });
  };

  console.log(selectedItems)
  const handleActivityToggle = (activity) => {
    setExpandedActivity(expandedActivity === activity ? null : activity);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getScopeCategoryData();
        if (data && Array.isArray(data.data)) {
          const formattedData = data.data.reduce((acc, item) => {
            const { scope, categoryName, newActivity } = item;
            if (!acc[scope]) acc[scope] = { categories: {} };
            if (!acc[scope].categories[categoryName])
              acc[scope].categories[categoryName] = { activity: {} };
            acc[scope].categories[categoryName].activity = {
              ...acc[scope].categories[categoryName].activity,
              ...newActivity,
            };
            return acc;
          }, {});

          setScopeCategories(formattedData);
        } else {
          console.error("Invalid data format");
        }
      } catch (err) {
        console.error("Failed to load scope categories");
      }
    };

    fetchData();
  }, []);

  const handleTabChange = (event, newValue) => {
    setSelectedScope(newValue);
    setSelectedCategory("");

    // Set the first category as selected by default
    if (scopeCategories[newValue] && Object.keys(scopeCategories[newValue].categories).length > 0) {
      setSelectedCategory(Object.keys(scopeCategories[newValue].categories)[0]);
    }
  };

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };

  const updateNewSchema = () => {
    const updatedSchema = {};

    Object.keys(selectedItems).forEach((scope) => {
      updatedSchema[scope] = { categories: {} };
      Object.keys(selectedItems[scope]).forEach((category) => {
        updatedSchema[scope].categories[category] = { activity: {} };
        Object.keys(selectedItems[scope][category]).forEach((activity) => {
          updatedSchema[scope].categories[category].activity[activity] = {
            inputs: [],
          };
          Object.keys(selectedItems[scope][category][activity]).forEach(
            (inputLabel) => {
              updatedSchema[scope].categories[category].activity[
                activity
              ].inputs.push({
                type: "input",
                label: inputLabel,
                inputs: [], // Nested inputs can be added here if needed
              });
            }
          );
        });
      });
    });

    setNewSchema(updatedSchema);
  };

  const isActivityChecked = (scope, category, activity) => {
    const activityData = selectedItems[scope]?.categories[category]?.activity[activity];
    if (!activityData) return false;
    return activityData.inputs.some(input => input.inputs.length > 0);
  };

  return (
    <div
      style={{
        height: "calc(100vh - 100px)",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <AppBar
        position="static"
        sx={{ borderRadius: 2, mb: 3, background: "transparent !important" }}
      >
        <Tabs
          value={selectedScope}
          onChange={handleTabChange}
          variant="fullWidth"
        >
          <Tab label="Scope 1" value="Scope 1" />
          <Tab label="Scope 2" value="Scope 2" />
          <Tab label="Scope 3" value="Scope 3" />
        </Tabs>
      </AppBar>

      {selectedScope === 'Scope 3' && showScope3Initial ? (
        <Scope3Initial onNext={handleScope3Next} location={location} categorySelections={categorySelections} setCategorySelections={setCategorySelections} isNextDisabled={isNextDisabled} setIsNextDisabled={setIsNextDisabled} setScopeCategories={setScopeCategories} />
      ) : <Box sx={{ display: "flex", flexGrow: 1, overflow: "hidden" }}>
        <Box
          sx={{
            width: "20%",
            borderRight: "1px solid #ccc",
            overflowY: "auto",
            bgcolor: "background.paper",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              mb: 2,
              fontFamily: "poppins",
              textDecoration: "underline",
              textAlign: "center",
            }}
          >
            Categories
          </Typography>
          {scopeCategories[selectedScope] &&
            Object.keys(scopeCategories[selectedScope].categories).map(
              (category) => (
                <CategoryBox
                  key={category}
                  className={selectedCategory === category ? "selected" : ""}
                  onClick={() => handleCategoryClick(category)}
                >
                  <CategoryIconWrapper>
                    <CategoryIcon />
                  </CategoryIconWrapper>
                  {category}
                </CategoryBox>
              )
            )}
        </Box>
        <Box
          sx={{
            width: "80%",
            overflowY: "auto",
            padding: "0 10px",
          }}
        >
          {selectedCategory && (
            <Paper
              elevation={3}
              sx={{
                p: 3,
                borderRadius: 2,
              }}
            >
              <Typography
                sx={{ fontFamily: "poppins" }}
                variant="h6"
                gutterBottom
              >
                {selectedCategory}
              </Typography>
              <Divider sx={{ mb: 2 }} />

              {Object.keys(
                scopeCategories[selectedScope]?.categories[selectedCategory]?.activity ||
                {}
              ).map((activity) => {
                return ["Cooling", "Heat & Steam", "Electricity Purchased"].includes(
                  activity
                ) ? (
                  <Paper
                    elevation={0}
                    key={activity}
                    sx={{
                      padding: 2,
                      borderTop: "1px solid #ccc",
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            !!selectedItems[selectedScope]?.categories[
                              selectedCategory
                            ]?.activity[activity]
                          }
                          onChange={() =>
                            handleCountryAssignment(
                              selectedScope,
                              selectedCategory,
                              activity
                            )
                          }
                        />
                      }
                      label={
                        <Typography
                          sx={{ fontFamily: "poppins" }}
                          variant="subtitle1"
                        >{`${activity}`}</Typography>
                      }
                    />
                  </Paper>
                ) : (
                  <StyledAccordion
                    key={activity}
                    expanded={expandedActivity === activity}
                    onChange={() => handleActivityToggle(activity)}
                  >
                    <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isActivityChecked(
                              selectedScope,
                              selectedCategory,
                              activity
                            )}
                            onChange={(event) => {
                              handleSelectionChange(
                                selectedScope,
                                selectedCategory,
                                activity
                              );
                              if (event.target.checked) {
                                setExpandedActivity(activity);
                              } else {
                                setExpandedActivity(null);
                              }
                            }}
                          />
                        }
                        label={
                          <Typography sx={{ fontFamily: "poppins" }} variant="subtitle1">
                            {activity}
                          </Typography>
                        }
                        onClick={(event) => event.stopPropagation()}
                      />
                    </StyledAccordionSummary>
                    <StyledAccordionDetails>
                      <Grid container spacing={2}>
                        {scopeCategories[selectedScope]?.categories[selectedCategory]
                          ?.activity[activity]?.inputs.map((input) => (
                            <Grid item xs={12} key={input.label}>
                              <Paper elevation={2} sx={{ padding: 2, marginBottom: 2 }}>
                                {input.inputs.length === 1 ? (
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={
                                          !!selectedItems[selectedScope]?.categories[
                                            selectedCategory
                                          ]?.activity[activity]?.inputs.find(
                                            (i) => i.label === input.label
                                          )
                                        }
                                        onChange={() =>
                                          handleSelectionChange(
                                            selectedScope,
                                            selectedCategory,
                                            activity,
                                            input.label
                                          )
                                        }
                                      />
                                    }
                                    label={
                                      <Typography sx={{ fontFamily: "poppins" }}>
                                        {`${input.label}`}
                                      </Typography>
                                    }
                                  />
                                ) : (
                                  <StyledAccordion>
                                    <StyledAccordionSummary
                                      expandIcon={<ExpandMoreIcon />}
                                    >
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            checked={
                                              !!selectedItems[selectedScope]?.categories[
                                                selectedCategory
                                              ]?.activity[activity]?.inputs.find(
                                                (i) => i.label === input.label
                                              )
                                            }
                                            onChange={() =>
                                              handleSelectionChange(
                                                selectedScope,
                                                selectedCategory,
                                                activity,
                                                input.label
                                              )
                                            }
                                          />
                                        }
                                        label={
                                          <Typography sx={{ fontFamily: "poppins" }}>
                                            {input.label}
                                          </Typography>
                                        }
                                        onClick={(event) => event.stopPropagation()}
                                      />
                                    </StyledAccordionSummary>
                                    <StyledAccordionDetails>
                                      <Grid container spacing={2}>
                                        {input.inputs.map((subInput) => (
                                          <Grid item xs={6} key={subInput.label}>
                                            <FormControlLabel
                                              control={
                                                <Checkbox
                                                  checked={
                                                    !!selectedItems[selectedScope]
                                                      ?.categories[selectedCategory]
                                                      ?.activity[activity]?.inputs
                                                      .find((i) => i.label === input.label)
                                                      ?.inputs.find(
                                                        (si) => si.label === subInput.label
                                                      )
                                                  }
                                                  onChange={() =>
                                                    handleSelectionChange(
                                                      selectedScope,
                                                      selectedCategory,
                                                      activity,
                                                      input.label,
                                                      subInput.label
                                                    )
                                                  }
                                                />
                                              }
                                              label={
                                                <Typography sx={{ fontFamily: "poppins" }}>
                                                  {subInput.label}
                                                </Typography>
                                              }
                                            />
                                          </Grid>
                                        ))}
                                      </Grid>
                                    </StyledAccordionDetails>
                                  </StyledAccordion>
                                )}
                              </Paper>
                            </Grid>
                          ))}
                      </Grid>
                    </StyledAccordionDetails>
                  </StyledAccordion>
                );
              })}
            </Paper>
          )}

        </Box>
      </Box>}
      <Box maxWidth="90%" sx={buttonGroupStyle}>
        {confirmSubmission ? (
          <div style={buttonGroupStyle}>
            <ResetButton
              variant="contained"
              onClick={() => setConfirmSubmission(false)}
            >
              <Typography fontFamily="Raleway" fontWeight={600}>No</Typography>
            </ResetButton>
            <SaveButton
              variant="contained"
              onClick={handleSubmit}
              startIcon={showLoader ? <CircularProgress size={20} style={{ color: "white" }} /> : null}
            >
              <Typography fontFamily="Raleway" fontWeight={600}>Yes</Typography>
            </SaveButton>
          </div>
        ) : (
          <div style={buttonGroupStyle}>
            <ResetButton
              variant="outlined"
              onClick={handleFormReset}
              disabled={confirmSubmission}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="21"
                viewBox="0 0 21 24"
                fill="none"
              >
                <path
                  d="M9.09375 23.125C6.53125 22.9167 4.375 21.8646 2.625 19.9688C0.875 18.0729 0 15.8229 0 13.2188C0 11.6146 0.369792 10.1042 1.10938 8.6875C1.84896 7.27083 2.88542 6.11458 4.21875 5.21875L5.5625 6.5625C4.39583 7.25 3.48958 8.19271 2.84375 9.39062C2.19792 10.5885 1.875 11.8646 1.875 13.2188C1.875 15.3021 2.5625 17.1042 3.9375 18.625C5.3125 20.1458 7.03125 21.0208 9.09375 21.25V23.125ZM10.9688 23.125V21.25C13.0521 21 14.7708 20.1198 16.125 18.6094C17.4792 17.099 18.1562 15.3021 18.1562 13.2188C18.1562 10.9479 17.3698 9.02604 15.7969 7.45312C14.224 5.88021 12.3021 5.09375 10.0312 5.09375H9.40625L11.2812 6.96875L9.9375 8.3125L5.78125 4.15625L9.9375 0L11.2812 1.34375L9.40625 3.21875H10.0312C12.8229 3.21875 15.1875 4.19271 17.125 6.14062C19.0625 8.08854 20.0312 10.4479 20.0312 13.2188C20.0312 15.8229 19.1615 18.0729 17.4219 19.9688C15.6823 21.8646 13.5312 22.9167 10.9688 23.125Z"
                  fill="#FA3F3F"
                />
              </svg>
              <Typography fontFamily="Raleway" fontWeight={600}>Reset</Typography>
            </ResetButton>
            <SaveButton
              variant="contained"
              onClick={() => setConfirmSubmission(true)}
              startIcon={showLoader ? <CircularProgress size={20} style={{ color: "white" }} /> : null}
            >
              <Typography fontFamily="Raleway" fontWeight={600}>Submit</Typography>
            </SaveButton>
          </div>
        )}
      </Box>

    </div>
  );
};
