import React, {
  useState,
  useMemo,
  useCallback,
  useEffect,
  useRef,
} from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Tabs,
  Tab,
  Box,
  Typography,
  MenuItem,
  Select,
  TextField,
  FormControl,
  InputLabel,
  Container,
  RadioGroup,
  FormControlLabel,
  Radio,
  InputAdornment,
  IconButton,
  Tooltip,
} from "@mui/material";
import { styled } from "@mui/system";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { CircularProgress, Grid } from "@material-ui/core";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Iconify from "../../components/iconify";
import * as Yup from "yup";
import { Formik, Field, Form } from "formik";
import { useSnackbar } from "notistack";
import markergif from "../Assets2/location.gif";
import { postBatchData } from "../../services/corporatesuppliers";
import { getLocationDataByLocationId } from "../../services/corporatecanvas";
import { APP_KEYS } from "../../utils/constants/AppKeyConstants";
import { PurchasedGoods } from "./PurchasedGoods";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import DescriptionIcon from "@mui/icons-material/Description";
import TableViewIcon from "@mui/icons-material/TableView";
import PhotoIcon from "@mui/icons-material/Photo";

const ResetButton = styled(Button)({
  textTransform: "none",
  boxShadow: "none",
  fontSize: 16,
  fontWeight: "bolder",
  width: "15%",
  border: "1px solid",
  lineHeight: 1.5,
  backgroundColor: "#fff",
  borderColor: "#FA3F3F",
  color: "#FA3F3F",
  fontFamily: "Poppins",
  alignItems: "center",
  justifyContent: "space-evenly",
  "&:hover": {
    backgroundColor: "#fff",
    borderColor: "#FA3F3F",
    boxShadow: "0px 3px 8px rgba(0, 0, 0, 0.24)",
  },
});

const SaveButton = styled(Button)({
  textTransform: "none",
  boxShadow: "none",
  fontSize: 16,
  fontWeight: "bolder",
  width: "15%",
  border: "1px solid #149BA1",
  lineHeight: 1.5,
  backgroundColor: "#E2E2E2",
  color: "#030229",
  fontFamily: "Poppins",
  alignItems: "center",
  justifyContent: "space-evenly",
  "&:hover": {
    backgroundColor: "#E2E2E2",
    borderColor: "#149BA1",
    boxShadow: "0px 3px 8px rgba(0, 0, 0, 0.24)",
  },
});

const buttonGroupStyle = {
  width: "92%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-evenly",
  padding: "10px 0",
  position: "fixed",
  bottom: 0,
  backgroundColor: "#f6f8fe",
};

const StyledTabs = styled(Tabs)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(3),
  borderBottom: `1px solid ${theme.palette.divider}`,
  "& .MuiTab-root": {
    minWidth: "auto",
    marginRight: theme.spacing(3),
    padding: theme.spacing(0, 3),
    borderRadius: theme.shape.borderRadius,
    "&.Mui-selected": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
  },
}));

const CenteredTabs = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
}));

const TabPanel = React.memo(({ children, value, index, ...other }) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
    {...other}
  >
    {value === index && <Box pl={1}>{children}</Box>}
  </div>
));

const DynamicForm2 = React.memo(
  ({ scopeData, scope, formData, setFormData }) => {
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedMethods, setSelectedMethods] = useState({});
    const fileInputRefs = useRef({});
    const [openDialog, setOpenDialog] = useState(false);
    const [currentUploadInfo, setCurrentUploadInfo] = useState(null);
    const [uploadChoice, setUploadChoice] = useState("");
    const [uploadReason, setUploadReason] = useState("");

    const handleOpenDialog = (
      subcategory,
      inputLabel,
      activityInputName,
      fileType
    ) => {
      setCurrentUploadInfo({
        subcategory,
        inputLabel,
        activityInputName,
        fileType,
      });
      setOpenDialog(true);
      setUploadChoice("");
      setUploadReason("");
    };

    const handleCloseDialog = () => {
      setOpenDialog(false);
      setCurrentUploadInfo(null);
    };

    const handleUploadChoice = (event) => {
      setUploadChoice(event.target.value);
    };

    const handleUploadReason = (event) => {
      setUploadReason(event.target.value);
    };

    const handleNextClick = () => {
      if (uploadChoice === "yes") {
        fileInputRefs.current[
          `${currentUploadInfo.subcategory}-${currentUploadInfo.inputLabel}-${
            currentUploadInfo.activityInputName
          }${
            currentUploadInfo.fileType === "emissionFactor"
              ? "-emissionFactorFile"
              : ""
          }`
        ]?.current?.click();
      }

      if (uploadChoice === "no"){
        setFormData((prevFormData) => ({
          ...prevFormData,
          [scope]: {
            ...(prevFormData[scope] || {}),
            [selectedCategory]: {
              ...(prevFormData[scope]?.[selectedCategory] || {}),
              [currentUploadInfo?.subcategory]: {
                ...(prevFormData[scope]?.[selectedCategory]?.[currentUploadInfo?.subcategory] || {}),
                [currentUploadInfo?.inputLabel]: {
                  ...(prevFormData[scope]?.[selectedCategory]?.[currentUploadInfo?.subcategory]?.[currentUploadInfo?.inputLabel] || {}),
                  [currentUploadInfo?.activityInputName]: {
                    ...(prevFormData[scope]?.[selectedCategory]?.[currentUploadInfo?.subcategory]?.[currentUploadInfo?.inputLabel]?.[currentUploadInfo?.activityInputName] || {}),
                    [`${currentUploadInfo.fileType}File`]: null,
                    [`${currentUploadInfo.fileType}FileReason`]: uploadReason,
                  }
                }
              }
            }
          }
        }));
      }
      handleCloseDialog();
    };

    console.log(formData);

    const handleChange = useCallback((subcategory, inputLabel, methodName) => {
      setSelectedMethods((prev) => ({
        ...prev,
        [`${subcategory}-${inputLabel}`]: methodName,
      }));
    }, []);

    const handleActivityInputChange = (
      subcategory,
      inputLabel,
      inputName,
      fieldName,
      value
    ) => {
      setFormData((prev) => ({
        ...prev,
        [scope]: {
          ...prev[scope],
          [selectedCategory]: {
            ...prev[scope]?.[selectedCategory],
            [subcategory]: {
              ...prev[scope]?.[selectedCategory]?.[subcategory],
              [inputLabel]: {
                ...prev[scope]?.[selectedCategory]?.[subcategory]?.[inputLabel],
                [inputName]: {
                  ...prev[scope]?.[selectedCategory]?.[subcategory]?.[
                    inputLabel
                  ]?.[inputName],
                  [fieldName]: value,
                },
              },
            },
          },
        },
      }));
    };

    const handleFileUpload = (
      event,
      subcategory,
      inputLabel,
      activityInputName,
      fileType
    ) => {
      const file = event.target.files[0];
      if (file) {
        setFormData((prev) => ({
          ...prev,
          [scope]: {
            ...prev[scope],
            [selectedCategory]: {
              ...prev[scope]?.[selectedCategory],
              [subcategory]: {
                ...prev[scope]?.[selectedCategory]?.[subcategory],
                [inputLabel]: {
                  ...prev[scope]?.[selectedCategory]?.[subcategory]?.[
                    inputLabel
                  ],
                  [activityInputName]: {
                    ...prev[scope]?.[selectedCategory]?.[subcategory]?.[
                      inputLabel
                    ]?.[activityInputName],
                    [`${fileType}File`]: file,
                  },
                },
              },
            },
          },
        }));
      }
    };

    const renderFileIcon = (file) => {
      if (!file) return <AttachFileIcon />;

      const fileExtension = file.name.split(".").pop().toLowerCase();

      switch (fileExtension) {
        case "pdf":
          return <PictureAsPdfIcon />;
        case "doc":
        case "docx":
          return <DescriptionIcon />;
        case "csv":
          return <TableViewIcon />;
        case "webp":
          return <PhotoIcon />;
        case "jpg":
          return <PhotoIcon />;
        case "png":
          return <PhotoIcon />;
        case "jpeg":
          return <PhotoIcon />;
        case "svg":
          return <PhotoIcon />;
        default:
          return <FilePresentIcon />;
      }
    };

    const categories = scopeData?.categories || {};
    const subcategories = selectedCategory
      ? categories[selectedCategory]?.activity
      : {};

    return (
      <Box sx={{ display: "flex", height: "500px", overflow: "hidden" }}>
        <Box
          sx={{
            width: "20%",
            borderRight: "1px solid #ccc",
            height: "80%",
            overflowY: "auto",
            position: "sticky",
            top: 0,
          }}
        >
          <Typography variant="h6" sx={{ mb: 2 }}>
            Categories
          </Typography>
          {Object.keys(categories).length > 0 ? (
            Object.keys(categories).map((category) => (
              <Box
                key={category}
                sx={{
                  marginBottom: "10px",
                  padding: "12px 5px",
                  marginRight: "5px",
                  cursor: "pointer",
                  borderRadius: "4px",
                  backgroundColor:
                    selectedCategory === category ? "#e0f7fa" : "transparent",
                  "&:hover": {
                    backgroundColor: "#b2ebf2",
                  },
                }}
                onClick={() => setSelectedCategory(category)}
              >
                {category}
              </Box>
            ))
          ) : (
            <Typography>No categories available</Typography>
          )}
        </Box>
        <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
          <DialogTitle sx={{ fontFamily: 'Poppins', fontWeight: 600 }}>
            {currentUploadInfo?.fileType === 'emissionFactor' ? 'Emission Factor File Upload' : 'File Upload'}
          </DialogTitle>
          <DialogContent>
            <Typography sx={{ fontFamily: 'Poppins', mb: 2 }}>
              Do you have a reference file to upload ?
            </Typography>
            <RadioGroup value={uploadChoice} onChange={handleUploadChoice}>
              <FormControlLabel value="yes" control={<Radio />} label="Yes" />
              <FormControlLabel value="no" control={<Radio />} label="No" />
            </RadioGroup>
            {uploadChoice === 'no' && (
              <>
                <Typography sx={{ fontFamily: 'Poppins', mt: 2, mb: 1 }}>Reasons:</Typography>
                <RadioGroup value={uploadReason} onChange={handleUploadReason}>
                  {currentUploadInfo?.fileType === 'emissionFactor' ? (
                    <>
                      <FormControlLabel 
                        value="EF is derived from a well-known, commonly accepted practice but no document is provided" 
                        control={<Radio />} 
                        label="EF is derived from a well-known, commonly accepted practice but no document is provided" 
                      />
                      <FormControlLabel 
                        value="EF is based on internal calculations or estimates without any external validation" 
                        control={<Radio />} 
                        label="EF is based on internal calculations or estimates without any external validation" 
                      />
                      <FormControlLabel 
                        value="EF is a rough estimate with no clear basis" 
                        control={<Radio />} 
                        label="EF is a rough estimate with no clear basis" 
                      />
                    </>
                  ) : (
                    <>
                      <FormControlLabel 
                        value="Data is based on highly reliable internal systems (e.g., automated logs)" 
                        control={<Radio />} 
                        label="Data is based on highly reliable internal systems (e.g., automated logs)" 
                      />
                      <FormControlLabel 
                        value="Data is an estimate based on past experience or rough calculations" 
                        control={<Radio />} 
                        label="Data is an estimate based on past experience or rough calculations" 
                      />
                      <FormControlLabel 
                        value="Data is a rough guess with no underlying methodology" 
                        control={<Radio />} 
                        label="Data is a rough guess with no underlying methodology" 
                      />
                    </>
                  )}
                </RadioGroup>
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button 
              onClick={()=> handleNextClick()} 
              disabled={!uploadChoice || (uploadChoice === 'no' && !uploadReason)}
              sx={{ fontFamily: 'Poppins' }}
            >
              Next
            </Button>
          </DialogActions>
        </Dialog>
        <Box
          sx={{
            width: "80%",
            height: "80%",
            overflowY: "auto",
            position: "sticky",
            top: 0,
          }}
        >
          {Object.entries(subcategories || {}).map(
            ([subcategory, inputs], subIdx) => (
              <Box key={subIdx} ml={2} mb={2}>
                <Typography
                  variant="subtitle1"
                  gutterBottom
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "19px",
                    fontWeight: 600,
                    pb: 2,
                    textAlign: "center",
                  }}
                >
                  Activities for {subcategory}
                </Typography>
                {inputs?.inputs?.map((inputGroup, groupIdx) => (
                  <Box mb={4} key={groupIdx}>
                    <Typography
                      sx={{
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        fontWeight: "600",
                        fontSize: "16px",
                        color: "inherit",
                        fontFamily: "Poppins",
                        mb: 2,
                      }}
                    >
                      Inputs for {inputGroup.label}
                    </Typography>
                    <Grid container spacing={3}>
                      {inputGroup?.inputs?.map((input, inputIdx) => {
                        const methodName =
                          selectedMethods[`${subcategory}-${input.label}`];
                        const selectedMethod = input.methods.find(
                          (method) => method.name === methodName
                        );
                        const defaultMethod = input.methods[0]; // Default to the first method

                        return (
                          <Grid item xs={12} sm={6} md={4} key={inputIdx}>
                            <Box mb={2}>
                              <Typography
                                sx={{
                                  textOverflow: "ellipsis",
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  fontWeight: "400",
                                  fontSize: "14px",
                                  color: "inherit",
                                  fontFamily: "Poppins",
                                }}
                              >
                                {input.label}
                              </Typography>
                              <FormControl fullWidth>
                                <Select
                                  labelId={`method-select-label-${subcategory}-${input.label}`}
                                  id={`method-select-${subcategory}-${input.label}`}
                                  value={
                                    methodName ||
                                    (input.methods.length > 0
                                      ? input.methods[0].name
                                      : "")
                                  }
                                  onChange={(e) =>
                                    handleChange(
                                      subcategory,
                                      input.label,
                                      e.target.value
                                    )
                                  }
                                  MenuProps={{
                                    PaperProps: {
                                      style: {
                                        maxHeight: 48 * 4.5 + 8,
                                      },
                                    },
                                    getContentAnchorEl: null,
                                    anchorOrigin: {
                                      vertical: "bottom",
                                      horizontal: "center",
                                    },
                                    transformOrigin: {
                                      vertical: "top",
                                      horizontal: "center",
                                    },
                                    variant: "menu",
                                  }}
                                  sx={{
                                    width: "100%",
                                    height: "42px",
                                    backgroundColor: "#fff",
                                    borderRadius: "8px",
                                    fontFamily: "Poppins !important",
                                    fontWeight: 500,
                                    border: "none !important",
                                    "&:focus": {
                                      border: "none",
                                    },
                                  }}
                                >
                                  {input?.methods?.map((method, methodIdx) => (
                                    <MenuItem
                                      key={methodIdx}
                                      sx={{ textTransform: "capitalize" }}
                                      value={method.name}
                                    >
                                      {method.name}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Box>

                            {selectedMethod?.activityInputs?.length > 0 ||
                            defaultMethod?.activityInputs?.length > 0 ? (
                              <Grid container>
                                {(
                                  selectedMethod?.activityInputs ||
                                  defaultMethod?.activityInputs
                                )?.map((activityInput, activityIdx) => {
                                  if (
                                    !fileInputRefs.current[
                                      `${subcategory}-${input.label}-${activityInput.name}`
                                    ]
                                  ) {
                                    fileInputRefs.current[
                                      `${subcategory}-${input.label}-${activityInput.name}`
                                    ] = React.createRef();
                                  }
                                  if (
                                    !fileInputRefs.current[
                                      `${subcategory}-${input.label}-${activityInput.name}-emissionFactorFile`
                                    ]
                                  ) {
                                    fileInputRefs.current[
                                      `${subcategory}-${input.label}-${activityInput.name}-emissionFactorFile`
                                    ] = React.createRef();
                                  }

                                  const selectedUnit =
                                    formData[scope]?.[selectedCategory]?.[
                                      subcategory
                                    ]?.[input.label]?.[activityInput.name]
                                      ?.unit || "";

                                  return (
                                    <Grid item xs={12} key={activityIdx}>
                                      <Box>
                                        <>
                                          <Box
                                            sx={{
                                              display: "flex",
                                              alignItems: "center",
                                              gap: 2,
                                            }}
                                          >
                                            <Typography
                                              sx={{
                                                textOverflow: "ellipsis",
                                                overflow: "hidden",
                                                whiteSpace: "nowrap",
                                                fontWeight: "400",
                                                fontSize: "14px",
                                                color: "inherit",
                                                fontFamily: "Poppins",
                                              }}
                                            >
                                              {activityInput.name}
                                            </Typography>

                                            <Box sx={{ flexGrow: 1 }}>
                                              {activityInput.unit &&
                                              activityInput.unit.length ===
                                                1 ? (
                                                <Typography
                                                  sx={{
                                                    color: "#149BA1",
                                                    textDecoration: "underline",
                                                    fontFamily: "Poppins",
                                                  }}
                                                >
                                                  {activityInput.unit[0]?.name}
                                                </Typography>
                                              ) : (
                                                activityInput.unit &&
                                                activityInput.unit.length >
                                                  0 && (
                                                  <FormControl component="fieldset">
                                                    <RadioGroup
                                                      row
                                                      value={
                                                        selectedUnit ||
                                                        activityInput.unit[0]
                                                          ?.name ||
                                                        ""
                                                      }
                                                      onChange={(e) =>
                                                        handleActivityInputChange(
                                                          subcategory,
                                                          input.label,
                                                          activityInput.name,
                                                          "unit",
                                                          e.target.value
                                                        )
                                                      }
                                                    >
                                                      {activityInput.unit.map(
                                                        (unit, unitIdx) => (
                                                          <FormControlLabel
                                                            key={unitIdx}
                                                            value={unit.name}
                                                            control={
                                                              <Radio
                                                                sx={{
                                                                  color:
                                                                    "#149BA1",
                                                                  "&.Mui-checked":
                                                                    {
                                                                      color:
                                                                        "#149BA1",
                                                                    },
                                                                }}
                                                              />
                                                            }
                                                            label={
                                                              <Typography
                                                                sx={{
                                                                  fontFamily:
                                                                    "Poppins",
                                                                  color:
                                                                    "#149BA1",
                                                                }}
                                                              >
                                                                {unit.name}
                                                              </Typography>
                                                            }
                                                            sx={{ mr: 2 }}
                                                          />
                                                        )
                                                      )}
                                                    </RadioGroup>
                                                  </FormControl>
                                                )
                                              )}
                                            </Box>
                                          </Box>
                                          <TextField
                                            type={
                                              activityInput.inputType || "text"
                                            }
                                            variant="outlined"
                                            value={
                                              formData[scope]?.[
                                                selectedCategory
                                              ]?.[subcategory]?.[input.label]?.[
                                                activityInput.name
                                              ]?.value || ""
                                            }
                                            onChange={(e) =>
                                              handleActivityInputChange(
                                                subcategory,
                                                input.label,
                                                activityInput.name,
                                                "value",
                                                e.target.value
                                              )
                                            }
                                            InputProps={{
                                              endAdornment: (
                                                <InputAdornment position="end">
                                                  <Tooltip
                                                    title={
                                                      formData[scope]?.[
                                                        selectedCategory
                                                      ]?.[subcategory]?.[
                                                        input.label
                                                      ]?.[activityInput.name]
                                                        ?.uploadedFile
                                                        ? formData[scope]?.[
                                                            selectedCategory
                                                          ]?.[subcategory]?.[
                                                            input.label
                                                          ]?.[
                                                            activityInput.name
                                                          ]?.uploadedFile.name
                                                        : "Attach file"
                                                    }
                                                  >
                                                    <IconButton
                                                      onClick={() =>
                                                        handleOpenDialog(
                                                          subcategory,
                                                          input.label,
                                                          activityInput.name,
                                                          "uploaded"
                                                        )
                                                      }
                                                      edge="end"
                                                      color="primary"
                                                      aria-label="upload file"
                                                    >
                                                      {renderFileIcon(
                                                        formData[scope]?.[
                                                          selectedCategory
                                                        ]?.[subcategory]?.[
                                                          input.label
                                                        ]?.[activityInput.name]
                                                          ?.uploadedFile
                                                      )}
                                                    </IconButton>
                                                  </Tooltip>
                                                  <input
                                                    ref={
                                                      fileInputRefs.current[
                                                        `${subcategory}-${input.label}-${activityInput.name}`
                                                      ]
                                                    }
                                                    type="file"
                                                    accept="*/*"
                                                    style={{ display: "none" }}
                                                    onChange={(e) =>
                                                      handleFileUpload(
                                                        e,
                                                        subcategory,
                                                        input.label,
                                                        activityInput.name,
                                                        "uploaded"
                                                      )
                                                    }
                                                  />
                                                </InputAdornment>
                                              ),
                                            }}
                                            sx={{
                                              width: "100%",
                                              fontFamily: "Poppins",
                                              mb: 2,
                                            }}
                                          />
                                        </>
                                        <>
                                          <Box
                                            sx={{
                                              display: "flex",
                                              alignItems: "center",
                                              gap: 2,
                                            }}
                                          >
                                            <Typography
                                              sx={{
                                                textOverflow: "ellipsis",
                                                overflow: "hidden",
                                                whiteSpace: "nowrap",
                                                fontWeight: "400",
                                                fontSize: "14px",
                                                color: "inherit",
                                                fontFamily: "Poppins",
                                              }}
                                            >
                                              Emission Factor for{" "}
                                              {activityInput.name}
                                            </Typography>
                                          </Box>
                                          <TextField
                                          label={'in kgCO₂e'}
                                            type={
                                              activityInput.inputType || "text"
                                            }
                                            variant="outlined"
                                            value={
                                              formData[scope]?.[
                                                selectedCategory
                                              ]?.[subcategory]?.[input.label]?.[
                                                activityInput.name
                                              ]?.emissionFactor || ""
                                            }
                                            onChange={(e) =>
                                              handleActivityInputChange(
                                                subcategory,
                                                input.label,
                                                activityInput.name,
                                                "emissionFactor",
                                                e.target.value
                                              )
                                            }
                                            InputProps={{
                                              endAdornment: (
                                                <InputAdornment position="end">
                                                  <Tooltip
                                                    title={
                                                      formData[scope]?.[
                                                        selectedCategory
                                                      ]?.[subcategory]?.[
                                                        input.label
                                                      ]?.[activityInput.name]
                                                        ?.emissionFactorFile
                                                        ? formData[scope]?.[
                                                            selectedCategory
                                                          ]?.[subcategory]?.[
                                                            input.label
                                                          ]?.[
                                                            activityInput.name
                                                          ]?.emissionFactorFile
                                                            .name
                                                        : "Attach emission factor file"
                                                    }
                                                  >
                                                    <IconButton
                                                      onClick={() =>
                                                        handleOpenDialog(
                                                          subcategory,
                                                          input.label,
                                                          activityInput.name,
                                                          "emissionFactor"
                                                        )
                                                      }
                                                      edge="end"
                                                      color="primary"
                                                      aria-label="upload emission factor file"
                                                    >
                                                      {renderFileIcon(
                                                        formData[scope]?.[
                                                          selectedCategory
                                                        ]?.[subcategory]?.[
                                                          input.label
                                                        ]?.[activityInput.name]
                                                          ?.emissionFactorFile
                                                      )}
                                                    </IconButton>
                                                  </Tooltip>
                                                  <input
                                                    ref={
                                                      fileInputRefs.current[
                                                        `${subcategory}-${input.label}-${activityInput.name}-emissionFactorFile`
                                                      ]
                                                    }
                                                    type="file"
                                                    accept="*/*"
                                                    style={{ display: "none" }}
                                                    onChange={(e) =>
                                                      handleFileUpload(
                                                        e,
                                                        subcategory,
                                                        input.label,
                                                        activityInput.name,
                                                        "emissionFactor"
                                                      )
                                                    }
                                                  />
                                                </InputAdornment>
                                              ),
                                            }}
                                            sx={{
                                              width: "100%",
                                              fontFamily: "Poppins",
                                              mb: 2,
                                            }}
                                          />
                                        </>
                                      </Box>
                                    </Grid>
                                  );
                                })}
                              </Grid>
                            ) : (
                              <Typography sx={{ fontFamily: "Poppins" }}>
                                No inputs available for this activity.
                              </Typography>
                            )}
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Box>
                ))}
              </Box>
            )
          )}
        </Box>
      </Box>
    );
  }
);

export const ActivityDialog = ({
  open,
  handleClose,
  handleSaveLocation,
  isLocationSaved,
  selectedLocation,
}) => {
  const userInfo = JSON.parse(localStorage.getItem(APP_KEYS.USER_INFO));
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedDate, setSelectedDate] = useState(null);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [formData, setFormData] = useState({
    "Scope 1": {},
    "Scope 2": {},
    "Scope 3": {},
  });
  const [confirmSubmission, setConfirmSubmission] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [showBackButton, setShowBackButton] = useState(true);
  const [roleId, setRoleId] = useState("");
  const { id } = useParams();
  const { search } = useLocation();
  const location = useLocation();
  const queryParams = new URLSearchParams(search);
  const locationName = queryParams.get("name");
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState(
    location?.state?.configuredTemplate || {
      "Scope 1": {},
      "Scope 2": {},
      "Scope 3": {},
    }
  );

  console.log(location?.state?.configuredTemplate);

  useEffect(() => {
    if (roleId === 6) {
      setShowBackButton(false);
    } else {
      setShowBackButton(true);
    }
  }, [userInfo]);

  const fetchConfiguredTemplate = async () => {
    const locationData = await getLocationDataByLocationId(id);
    if (!location?.state?.configuredTemplate && locationData) {
      setData(
        locationData[0]?.configuredTemplate || {
          "Scope 1": {},
          "Scope 2": {},
          "Scope 3": {},
        }
      );
    }
  };

  useEffect(() => {
    if (userInfo?.roles?.length > 0) {
      setRoleId(userInfo?.roles[0]?.roleId);
    }
  }, [userInfo?.roles]);

  useEffect(() => {
    fetchConfiguredTemplate();
  }, []);

  const validationSchema = Yup.object({
    selectedDate: Yup.date().required("Date is required"),
    formData: Yup.object({
      "Scope 1": Yup.object().required("Scope 1 data is required"),
      "Scope 2": Yup.object().required("Scope 2 data is required"),
      "Scope 3": Yup.object().required("Scope 3 data is required"),
    }),
  });

  const handleTabChange = useCallback((event, newValue) => {
    setSelectedTab(newValue);
  }, []);

  const handleDateChange = useCallback((date) => {
    setSelectedDate(date);
  }, []);

  const handleSubmit = useCallback(async () => {
    console.log("submit", uploadedFile);
    setShowLoader(true);
    try {
      const jsonData = {
        location: { id, locationName },
        date: selectedDate
          ? `${String(selectedDate.getMonth() + 1).padStart(
              2,
              "0"
            )}/${selectedDate.getFullYear()}`
          : null,
        "Scope 1": formData["Scope 1"],
        "Scope 2": formData["Scope 2"],
        "Scope 3": formData["Scope 3"],
      };

      await new Promise((resolve) => setTimeout(resolve, 1000));
      const requestPayload = new FormData();
      requestPayload.append("locationId", id);
      requestPayload.append("locationName", locationName);
      requestPayload.append("templateValue", JSON.stringify(jsonData));
      requestPayload.append(
        "month",
        String(selectedDate.getMonth() + 1).padStart(2, "0")
      );
      requestPayload.append("year", selectedDate.getFullYear());
      if (uploadedFile) {
        requestPayload.append("uploadedFile", uploadedFile);
      }

      const result = await postBatchData(requestPayload);

      if (result?.message === "error") {
        enqueueSnackbar("Data not submitted", { variant: "error" });
      } else {
        enqueueSnackbar("Data submitted successfully", { variant: "success" });
      }
      setConfirmSubmission(false);
    } catch (error) {
      console.log(error);
      enqueueSnackbar("An error occurred. Please try again.", {
        variant: "error",
      });
    } finally {
      setShowLoader(false);
    }
  }, [selectedDate, formData, id, locationName, handleClose, enqueueSnackbar]);
  const handleFormReset = useCallback(() => {
    setFormData({
      "Scope 1": {},
      "Scope 2": {},
      "Scope 3": {},
    });
    setSelectedDate(null);
    setConfirmSubmission(false);
  }, []);

  return (
    <Box>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "10px 10px",
        }}
      >
        {showBackButton && (
          <Button
            sx={{
              marginBottom: "10px",
              backgroundColor: "#222",
              "&:hover": { backgroundColor: "#149BA1" },
              color: "#fff",
              display: "flex",
              width: "100px",
              alignItems: "center",
              borderRadius: "10px",
            }}
            onClick={() => navigate("/carboncompete/suppliercorporatecanvas")}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <Iconify icon="icon-park-outline:back" color="white" />
              <Typography
                fontFamily="Poppins"
                style={{ textTransform: "capitalize" }}
              >
                Back
              </Typography>
            </div>
          </Button>
        )}

        <div style={{ textAlign: "center" }}>
          <Typography
            variant="subtitle1"
            gutterBottom
            sx={{
              fontFamily: "poppins",
              fontSize: "29px",
              fontWeight: 600,
              textAlign: "center",
              border: "1px solid #ccc",
              borderRadius: "10px",
              boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.1)",
              display: "inline-flex",
              alignItems: "center",
              padding: "3px 20px",
            }}
          >
            Activity inputs for {locationName}
            <span style={{ marginLeft: "10px" }}>
              <img
                style={{ width: "35px", height: "35px" }}
                src={markergif}
                alt="marker"
              />
            </span>
          </Typography>
        </div>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            views={["month", "year"]}
            value={selectedDate}
            onChange={handleDateChange}
            sx={{
              borderRadius: 3,
              width: "31%",
            }}
            slotProps={{
              textField: {
                placeholder: "Month & Year",
              },
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                margin="normal"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    fontFamily: "Poppins",
                  },
                }}
              />
            )}
          />
        </LocalizationProvider>
      </div>
      <div>
        <CenteredTabs>
          <StyledTabs value={selectedTab} onChange={handleTabChange}>
            <Tab
              label="Scope 1"
              sx={{ fontFamily: "Poppins, Arial, sans-serif" }}
            />
            <Tab
              label="Scope 2"
              sx={{ fontFamily: "Poppins, Arial, sans-serif" }}
            />
            <Tab
              label="Scope 3"
              sx={{ fontFamily: "Poppins, Arial, sans-serif" }}
            />
          </StyledTabs>
        </CenteredTabs>
      </div>
      <TabPanel value={selectedTab} index={0}>
        <DynamicForm2
          scopeData={data["Scope 1"]}
          scope="Scope 1"
          formData={formData}
          setFormData={setFormData}
          uploadedFile={uploadedFile}
          setUploadedFile={setUploadedFile}
        />
      </TabPanel>
      <TabPanel value={selectedTab} index={1}>
        <DynamicForm2
          scopeData={data["Scope 2"]}
          scope="Scope 2"
          formData={formData}
          setFormData={setFormData}
          uploadedFile={uploadedFile}
          setUploadedFile={setUploadedFile}
        />
      </TabPanel>
      <TabPanel value={selectedTab} index={2}>
        <DynamicForm2
          scopeData={data["Scope 3"]}
          scope="Scope 3"
          formData={formData}
          setFormData={setFormData}
          uploadedFile={uploadedFile}
          setUploadedFile={setUploadedFile}
        />
      </TabPanel>
      <Box maxWidth="90%" sx={buttonGroupStyle}>
        {confirmSubmission ? (
          <div style={buttonGroupStyle}>
            <ResetButton
              variant="contained"
              onClick={() => setConfirmSubmission(false)}
            >
              <Typography fontFamily="Raleway" fontWeight={600}>
                No
              </Typography>
            </ResetButton>
            <SaveButton
              variant="contained"
              onClick={handleSubmit}
              startIcon={
                showLoader ? (
                  <CircularProgress size={20} style={{ color: "white" }} />
                ) : null
              }
            >
              <Typography fontFamily="Raleway" fontWeight={600}>
                Yes
              </Typography>
            </SaveButton>
          </div>
        ) : (
          <div style={buttonGroupStyle}>
            <ResetButton
              variant="outlined"
              onClick={handleFormReset}
              disabled={confirmSubmission}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="21"
                viewBox="0 0 21 24"
                fill="none"
              >
                <path
                  d="M9.09375 23.125C6.53125 22.9167 4.375 21.8646 2.625 19.9688C0.875 18.0729 0 15.8229 0 13.2188C0 11.6146 0.369792 10.1042 1.10938 8.6875C1.84896 7.27083 2.88542 6.11458 4.21875 5.21875L5.5625 6.5625C4.39583 7.25 3.48958 8.19271 2.84375 9.39062C2.19792 10.5885 1.875 11.8646 1.875 13.2188C1.875 15.3021 2.5625 17.1042 3.9375 18.625C5.3125 20.1458 7.03125 21.0208 9.09375 21.25V23.125ZM10.9688 23.125V21.25C13.0521 21 14.7708 20.1198 16.125 18.6094C17.4792 17.099 18.1562 15.3021 18.1562 13.2188C18.1562 10.9479 17.3698 9.02604 15.7969 7.45312C14.224 5.88021 12.3021 5.09375 10.0312 5.09375H9.40625L11.2812 6.96875L9.9375 8.3125L5.78125 4.15625L9.9375 0L11.2812 1.34375L9.40625 3.21875H10.0312C12.8229 3.21875 15.1875 4.19271 17.125 6.14062C19.0625 8.08854 20.0312 10.4479 20.0312 13.2188C20.0312 15.8229 19.1615 18.0729 17.4219 19.9688C15.6823 21.8646 13.5312 22.9167 10.9688 23.125Z"
                  fill="#FA3F3F"
                />
              </svg>
              <Typography fontFamily="Raleway" fontWeight={600}>
                Reset
              </Typography>
            </ResetButton>
            <SaveButton
              variant="contained"
              onClick={() => setConfirmSubmission(true)}
              startIcon={
                showLoader ? (
                  <CircularProgress size={20} style={{ color: "white" }} />
                ) : null
              }
            >
              <Typography fontFamily="Raleway" fontWeight={600}>
                Submit
              </Typography>
            </SaveButton>
          </div>
        )}
      </Box>
    </Box>
  );
};
