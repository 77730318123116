import React, { useEffect, useState } from 'react';
import {
  Tab,
  Tabs,
  List,
  ListItem,
  ListItemText,
  Container,
  Typography,
  Box,
  Divider,
  Checkbox,
  FormControlLabel,
  AppBar,
  Button,
  Paper,
  CircularProgress,
  Radio,
  RadioGroup, 
  FormControl, 
  TextField
} from '@mui/material';
import { putLocationData } from '../../services/corporatecanvas';

export const Scope3Initial = ({ onNext, location, categorySelections, isNextDisabled, setCategorySelections, setIsNextDisabled, setScopeCategories}) => {
    const scope3Categories = [
      "Purchased Goods and Services",
      "Hotel Stay",
      "Supplied Water",
      "Treated Water",
      "End of life treatment of sold products",
      "Use of Sold Products",
      "Processing of Sold Products",
      "Downstream Transportation distribution\n",
      "Employee Commute",
      "Franchsies",
      "Downstream leased asset",
      "Upstream Transportation distribution",
      "Upstream leased asset",
      "Business Travel",
      "Waste"
    ];
  
    useEffect(() => {
      const allFilled = scope3Categories.every(category => {
        const selection = categorySelections[category];
        return selection && selection.selected && (selection.selected === 'Yes' || (selection.selected === 'No' && selection.reason));
      });
      setIsNextDisabled(!allFilled);
    }, [categorySelections]);
  
    const handleCategoryChange = (category, value) => {
      setCategorySelections(prev => ({
        ...prev,
        [category]: {
          ...prev[category],
          selected: value,
          reason: value === 'Yes' ? null : (prev[category]?.reason || '')
        }
      }));
    };
  
    const handleReasonChange = (category, reason) => {
      setCategorySelections(prev => ({
        ...prev,
        [category]: {
          ...prev[category],
          reason
        }
      }));
    };
  
    const handleNext = async() => {
      const payload = {
        locationId: location.id,
        scope3Disclosure: categorySelections
      }
  
      const result = await putLocationData(payload)
  
      const selectedCategories = Object.entries(categorySelections)
        .filter(([_, value]) => value.selected === 'Yes')
        .map(([category, _]) => category);
        
      setScopeCategories((prev) => {
        Object.keys(prev["Scope 3"]?.categories).forEach((key) => {
          if (!selectedCategories.includes(key)) {
            delete prev["Scope 3"].categories[key];
          }
        });
        
        return prev;
      });
        
      onNext(selectedCategories, categorySelections);
    };
  
    return (
      <Box sx={{ 
        position: 'relative',
        height: '100%',
        overflow: 'auto',
        p: 3,
      }}>
        <Box
          sx={{
            position: 'sticky',
            top: 16,
            float: 'right',
            zIndex: 1000,
          }}
        >
          <Button 
            variant="contained" 
            onClick={handleNext} 
            disabled={isNextDisabled}
          >
            Next
          </Button>
        </Box>
        <Typography variant="h6" gutterBottom>Please disclose the applicable Scope 3 categories for {location.name} to proceed </Typography>
        {scope3Categories.map((category) => (
          <Box key={category} sx={{ mb: 2, clear: 'both' }}>
            <Typography variant="subtitle1">{category}</Typography>
            <FormControl component="fieldset">
              <RadioGroup
                row
                value={categorySelections[category]?.selected || ''}
                onChange={(e) => handleCategoryChange(category, e.target.value)}
              >
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
            {categorySelections[category]?.selected === 'No' && (
              <TextField
                fullWidth
                label="Reason"
                value={categorySelections[category]?.reason || ''}
                onChange={(e) => handleReasonChange(category, e.target.value)}
                margin="normal"
              />
            )}
          </Box>
        ))}
      </Box>
    );
};