import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  Select,
  MenuItem,
  Typography,
  InputLabel,
  InputAdornment,
} from "@mui/material";
import {
  LocationOnOutlined,
  ExpandMore,
  Webhook,
  Person2Outlined,
  LocalLibraryOutlined,
} from "@mui/icons-material";
import AddNewCorporateSupplierDialog from "./AddNewSupplier";
import { getAllCorporateSuppliers } from "../../services/corporatesuppliers";
import { putLocationData } from "../../services/corporatecanvas";
import { useSnackbar } from "notistack";
import { APP_KEYS } from '../../utils/constants/AppKeyConstants';
const userData = JSON.parse(localStorage.getItem(APP_KEYS.USER_INFO));

const AddLocation = ({ location, setActiveTab }) => {
  const [selectedType, setSelectedType] = useState(location?.type || "");
  const [checker, setChecker] = useState("");
  const [openCorporateSupplierDialog, setOpenCorporateSupplierDialog] =
    useState(false);
  const [availableCorporateSuppliers, setAvailableCorporateSuppliers] =
    useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState({});
  const categories = [
    "Production",
    "Warehouse",
    "Upstream Leased Assets",
    "Packaging",
    "Factory",
    "Others",
    "Downstream leased assets",
    "Franchise",
  ];
  const { enqueueSnackbar } = useSnackbar();

  const handleTypeChange = (e) => {
    setSelectedType(e.target.value);
  };

  console.log(availableCorporateSuppliers)
  const fetchAllCorporateSuppliers = async () => {
    const response = await getAllCorporateSuppliers();
    setAvailableCorporateSuppliers([
      ...response.data,
      {
        compId: userData?.compId,
        userId: userData?.userId,
        supplierId: 0,
        userName: "Assign it to Self"
      }
      
    ]);
    setSelectedSupplier(location?.supplierId ? response?.data.filter((item)=>item?.supplierId===location?.supplierId)[0]:{
      compId: userData?.compId,
      userId: userData?.userId,
      supplierId: 0,
      userName: "Assign it to Self"
    })
  };

  console.log(selectedSupplier)
  useEffect(() => {
    fetchAllCorporateSuppliers();
  }, []);

  const addNewCorporateSupplier = () => {
    setOpenCorporateSupplierDialog(true);
  };

  const handleSelectSupplier = (event) => {
    console.log("handle select supplier");
    console.log(event);
    const selectedUserName = event.target.value;
    const selected = availableCorporateSuppliers.find(
      (supplier) => supplier.userName === selectedUserName
    );
    setSelectedSupplier(selected);
  };

  console.log(location)
  console.log(selectedSupplier)
  const handleNext = async() => {
    if (!Object.keys(selectedSupplier).length || !selectedType) {
      enqueueSnackbar("Please Add supplier and type to proceed", {
        variant:"error"
      });
    
      return;
    }

    const payLoad = {
      locationId: location.id,
      supplierId: selectedSupplier.supplierId,
      locationType: selectedType,
    };

    const result = await putLocationData(payLoad)
    console.log(result)

    if (result){
      enqueueSnackbar(`Supplier and Type configured for ${location.name}`, {
        variant: "success",
      });
    }
    setActiveTab(1)
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 10,
        padding: 4,
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          columnGap: 10,
          rowGap: 20,
        }}
      >
        <Box display="flex" alignItems="center" height={48}>
          <LocationOnOutlined
            sx={{ marginRight: 1, color: "#00C9D2", fontSize: 48 }}
          />
          <Typography
            variant="h6"
            color={"#00C9D2"}
            sx={{ lineHeight: "48px" }}
          >
            {location?.name}
          </Typography>
        </Box>
        <Box>
          <FormControl fullWidth>
            <InputLabel
              id="type-select-label"
              sx={{
                "&.Mui-focused": {
                  color: "#00C9D2",
                },
              }}
            >
              Change Type of Location
            </InputLabel>
            <Select
              labelId="type-select-label"
              id="type-select"
              value={selectedType || "Change Type of Location"}
              onChange={handleTypeChange}
              label="Change Type of Location"
              sx={{
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#00C9D2",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#00C9D2",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#00C9D2",
                },
                "& .MuiSvgIcon-root": {
                  color: "#00C9D2",
                },
                height: "46px",
                width: "360px",
              }}
              IconComponent={(props) => (
                <ExpandMore {...props} sx={{ color: "#00C9D2" }} />
              )}
              startAdornment={
                <InputAdornment position="start">
                  <Webhook sx={{ color: "#00C9D2" }} />
                  {selectedType ? "" : "Select Type of location"}
                </InputAdornment>
              }
            >
              {categories.map((category) => (
                <MenuItem key={category} value={category}>
                  {category}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <FormControl fullWidth>
          <InputLabel
            id="assign-user"
            sx={{
              "&.Mui-focused": {
                color: "#00C9D2",
              },
            }}
          >
            Assign User
          </InputLabel>
          <Select
            value={selectedSupplier?.userName || ""}
            onChange={handleSelectSupplier}
            labelId="assign-user"
            label="Assign User"
            sx={{
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "#00C9D2",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "#00C9D2",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#00C9D2",
              },
              "& .MuiSvgIcon-root": {
                color: "#00C9D2",
              },
              height: "46px",
              width: "360px",
            }}
            IconComponent={(props) => (
              <ExpandMore {...props} sx={{ color: "#00C9D2" }} />
            )}
            startAdornment={
              <InputAdornment position="start">
                <Person2Outlined sx={{ color: "#00C9D2" }} />
              </InputAdornment>
            }
          >
            {availableCorporateSuppliers.map((supplier) => (
              <MenuItem key={supplier.Id} value={supplier.userName}>
                {supplier.userName}
              </MenuItem>
            ))}
          </Select>

          <Typography
            variant="body2"
            sx={{
              marginTop: 1,
            }}
          >
            Click Here to{" "}
            <span
              style={{
                color: "red",
                cursor: "pointer",
              }}
              onClick={addNewCorporateSupplier}
            >
              Add
            </span>
          </Typography>
        </FormControl>

        <FormControl fullWidth>
          <InputLabel
            id="assign-checker"
            sx={{
              "&.Mui-focused": {
                color: "#00C9D2",
              },
            }}
          >
            {" "}
            Assign Checker{" "}
          </InputLabel>
          <Select
            value={checker}
            onChange={(e) => setChecker(e.target.value)}
            labelId="assign-checker"
            label="Assign Checker"
            sx={{
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "#00C9D2",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "#00C9D2",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#00C9D2",
              },
              "& .MuiSvgIcon-root": {
                color: "#00C9D2",
              },
              height: "46px",
              width: "360px",
            }}
            IconComponent={(props) => (
              <ExpandMore {...props} sx={{ color: "#00C9D2" }} />
            )}
            startAdornment={
              <InputAdornment position="start">
                <LocalLibraryOutlined sx={{ color: "#00C9D2" }} />
              </InputAdornment>
            }
          >
            <MenuItem value="Checker 1">Checker 1</MenuItem>
            <MenuItem value="Checker 2">Checker 2</MenuItem>
          </Select>
        </FormControl>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          mt: 2,
          backgroundColor: "#F4F4F4",
          width: "100%",
        }}
      >
        <Button
          variant="outlined"
          sx={{
            color: "red",
            borderColor: "red",
            mr: 28,
            width: "155px",
            height: "48px",
            p: 2,
          }}
        >
          Delete
        </Button>
        <Button
          variant="contained"
          sx={{
            bgcolor: "#00C9D2",
            "&:hover": { bgcolor: "#00A5AD" },
            width: "155px",
            height: "48px",
          }}
          onClick={handleNext}
        >
          Next
        </Button>
        <AddNewCorporateSupplierDialog
          open={openCorporateSupplierDialog}
          setOpen={setOpenCorporateSupplierDialog}
          fetchAllCorporateSuppliers={fetchAllCorporateSuppliers}
        />
      </Box>
    </Box>
  );
};

export default AddLocation;